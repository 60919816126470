import React from 'react'
import './Contact.css'
import ContactData from '../../Data/Contact.json'

function Contact() {
    return (
        <div className="main-content-contact" id="contact">
            <h2>{ContactData.title}</h2>
            <div className="contact-items-container">
                {ContactData.items.map((contactItem) => {
                    return (
                        <div className="contact-item">
                            <a className="contact-button" href={contactItem.link} target="_blank">
                                <i className={contactItem.icon + " contact-image"}></i>
                                <p>{contactItem.title}</p>
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Contact
