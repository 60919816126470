import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <footer className="footer-container">
            <small>Copyright &copy; {new Date().getFullYear()} Dr. Anjali Chaubal. All Rights Reserved.</small>
        </footer>
    )
}

export default Footer
