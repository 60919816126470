import React from 'react'
import './Gallery.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import gallery1 from '../../assets/images/Gallery/gallery-1.jpg'
import gallery2 from '../../assets/images/Gallery/gallery-2.jpg'
import gallery3 from '../../assets/images/Gallery/gallery-3.jpg'
import gallery4 from '../../assets/images/Gallery/gallery-4.jpg'
import gallery5 from '../../assets/images/Gallery/gallery-5.jpg'

function Gallery() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="main-content-gallery">
            <h2>Gallery</h2>
            <br />
            <Carousel autoPlay={true} infinite={true} containerClass="gallery-image-container" responsive={responsive}>
                <div>
                    <img className="gallery-image" src={gallery4} />
                </div>
                <div>
                    <img className="gallery-image" src={gallery5} />
                </div>
                <div>
                    <img className="gallery-image" src={gallery1} />
                </div>
                <div>
                    <img className="gallery-image" src={gallery2} />
                </div>
                <div>
                    <img className="gallery-image" src={gallery3} />
                </div>
            </Carousel>
        </div>
    )
}

export default Gallery
