import './About.css'
import React from 'react'
import AboutData from '../../Data/About.json'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import aboutImage from '../../assets/images/About/about.jpg'

function About() {
    return (
        <div className="main-content-about" id="about">
            <div className="about-content">
                <h2>{AboutData.title}</h2>
                <br />
                <br />
                <img src={aboutImage} className="about-image-container">
                </img>
                <br />
                <br />
                <p className="about-content-body">{AboutData.content}</p>
            </div>
        </div>
    );
}

export default About
