import './App.css';
import Navbar from './components/Navbar/Navbar';
import Intro from './components/Intro/Intro';
import Results from './components/Results/Results';
import Contact from './components/Contact/Contact';
import Reviews from './components/Reviews/Reviews';
import Gallery from './components/Gallery/Gallery';
import About from './components/About/About';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <>
      <Navbar />
      <Intro />
      <Reviews />
      <Results />
      <Gallery />
      <About />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
