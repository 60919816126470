import React, { useState } from 'react';
import './Navbar.css'
import NavData from '../../Data/Nav.json'

function Navbar() {
    const [clicked, setClicked] = useState(false)
    const scrollTo = (e) => {
        console.log(e.target.name)
        const section = document.querySelector(e.target.name);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setClicked(false)
    }
    return (
        <div className="main-nav-container">
            <div className="navbar-left-container">
                <h2 className="navbar-main-title">{NavData.title}</h2>
            </div>
            <div className="menu-icon">
                <a onClick={() => setClicked(!clicked)}>{
                    <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
                }</a>
            </div>
            <div className={clicked ? "navbar-right-container active" : "navbar-right-container"}>
                <ul className="navbar-right-list">
                    {NavData.options.map((navItem) => {
                        return (
                            < li className="navbar-list-item" >
                                <a className="navbar-button" name={navItem.link} onClick={scrollTo}>{navItem.title}</a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div >
    )
}

export default Navbar
