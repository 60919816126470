import React from 'react'
import './Intro.css'
import IntroData from '../../Data/Intro.json'

function Intro() {
    return (
        <div className="main-content-intro">
            <h1>{IntroData.title}</h1>
            <h3>{IntroData.subtitle}</h3>
            <p>{IntroData.tagline}</p>
        </div>
    )
}

export default Intro
