import './Reviews.css'
import React from 'react'
import ReviewData from '../../Data/Reviews.json'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Reviews() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="main-content-reviews" id="reviews">
            <h2>{ReviewData.title}</h2>
            <Carousel responsive={responsive}
                className="review-items-container"
                showDots={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
            >
                {ReviewData.items.map((reviewItem) => {
                    return (
                        <div className="review-item">
                            <p>{reviewItem.review}</p>
                            <br />
                            <h4>{"- " + reviewItem.from}</h4>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default Reviews
