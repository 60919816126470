import React, { useState } from 'react';
import './Results.css'
const ResultsData = require('../../Data/Results.json')

function Results() {
    const [selected, setSelected] = useState(ResultsData.years[0])
    const [showMore, setShowMore] = useState(false)
    const toggleYear = (e) => {
        setSelected(e.target.innerText)
    }

    return (
        <div className="main-content-results" id="results">
            <h2>Results</h2>
            <div className="results-year-select">
                {ResultsData.years.map((year) => {
                    return (
                        <div className={"results-year-select-item" + (selected == year ? " selected" : "")} onClick={toggleYear} > {year}</div>
                    )
                })}
            </div>
            <div className="marks-container">
                {ResultsData.items[selected].map((marksItem, idx) => {
                    if (idx > 5 && !showMore) {
                        return null
                    }
                    return (
                        <div className="marks-container-item">
                            <h3>{marksItem.name}</h3>
                            {marksItem.marks}
                        </div>
                    )
                })}
            </div>
            <br />
            <button className="show-more-button" onClick={() => {
                setShowMore(!showMore)
            }}>{showMore ? "SHOW LESS" : "SHOW MORE"}</button>
        </div >
    )
}

export default Results
